import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';

import {
    Layout
} from '../components/common';

import { TelegramChannel } from '../components/common/Promo'
import { Tags } from '../components/blog/Tags'
import { Categories } from '../components/blog/Categories'

import {
    Container,
    Title,
    PostList,
    PostListItem,
    Date,
    SubHeading,
    Preview,
    Content,
    Thumbnail,
    Text,
    PaginationContainer,
    PageNext,
    PagePrev,
} from '../pageStyles/AllPostStyle';

import {
  Divider,
} from '../pageStyles'

const BlogCategory = ({ data, pageContext }) => {
  console.log(pageContext.category)
  return pageContext.category === "News" ? 
        <NewsCategory data={data} pageContext={pageContext}/> : 
        <CommonCategory data={data} pageContext={pageContext}/>
}
export default BlogCategory;

export const query = graphql`
  query blogPostsListByCategory($category: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale:"it")
            title
            excerpt
            author
            ogimage
          }
        }
      }
    }
  }
`

const CommonCategory = ({data, pageContext}) => {
  const posts = data.allMarkdownRemark.edges;
  return (<Layout promotion={<TelegramChannel/>}>
          <Helmet>
              <title>{pageContext.category}</title>
              <meta name="description" content="Tutti gli articoli di NorthFPV"/>
          </Helmet>
          <Container>
          <Title>
              {pageContext.category}
          </Title>
          <PostList>
              {posts.map(( node, index ) => {
                  const { node : { frontmatter : { title, date, excerpt, author, ogimage}, fields : { slug } } } = node;
                  return (
                    <PostListItem>
                    <Link to={`${slug}`} key={index}>
                      <Content>
                        <Thumbnail src={ogimage ? ogimage : '/images/logo.png'}/>
                        <Text>
                          <Date>
                              {date} &nbsp;&middot;&nbsp; @{author}
                          </Date>
                          <SubHeading>
                              {title}
                          </SubHeading>
                          <Preview>
                              {excerpt}
                          </Preview>
                        </Text>
                    </Content>
                  </Link>
                </PostListItem>
                  )
              })}
          </PostList>
      </Container>
      </Layout>)
}

const NewsCategory = ({data, pageContext}) => {
  const posts = data.allMarkdownRemark.edges;
  console.log(pageContext)
  const { currentPage, numPages, tags, categories } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (<Layout promotion={<TelegramChannel/>}>
          <Helmet>
              <title>{pageContext.category}</title>
              <meta name="description" content="Le ultime notizie nel mondo FPV"/>
          </Helmet>
          <Container>
          <Categories list={categories}/>
          <Divider/>
          <Title>
              {pageContext.category}
          </Title>
          <PostList>
              {posts.map(( node, index ) => {
                  const { node : { frontmatter : { title, date, excerpt, author, ogimage}, fields : { slug } } } = node;
                  return (
                    <PostListItem>
                    <Link to={`${slug}`} key={index}>
                      <Content>
                        <Thumbnail src={ogimage ? ogimage : '/images/logo.png'}/>
                        <Text>
                          <Date>
                              {date} &nbsp;&middot;&nbsp; @{author}
                          </Date>
                          <SubHeading>
                              {title}
                          </SubHeading>
                          <Preview>
                              {excerpt}
                          </Preview>
                        </Text>
                    </Content>
                  </Link>
                </PostListItem>
                  )
              })}
              <PaginationContainer>
                {!isFirst ? 
                  <PagePrev href={prevPage} rel="prev">
                    ← Pagina precedente
                  </PagePrev>
                : <PagePrev/>}
                {!isLast && (
                  <PageNext href={nextPage} rel="next">
                    Pagina successiva →
                  </PageNext>
                )}
              </PaginationContainer>
          </PostList>
          <Divider/>
          <Tags list={tags}/>
      </Container>
      </Layout>   )
}